var searchKeys = [
// {
//   key: "title",
//   label: "视频名称",
//   placeholder: "",
//   required: false,
//   rules: [],
//   input: true
// },
// {
//   key: "sourcetype",
//   label: "来源",
//   placeholder: "请选择",
//   required: false,
//   rules: [],
//   select: true,
//   initialValue: 0
// },
// {
//   key: "date",
//   label: "创建时间",
//   format: "YYYY-MM-DD HH:mm",
//   placeholder: ["开始时间", "结束时间"],
//   rules: [],
//   dates: true
// },

{
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };