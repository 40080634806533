var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["dcVideoTag:btn:add"],
      expression: "['dcVideoTag:btn:add']"
    }],
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAction("add");
      }
    }
  }, [_vm._v("新建")]), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return i;
      },
      loading: _vm.loading
    },
    on: {
      change: _vm.tableChange
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref, index) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record) {
          return _c("div", {
            key: index
          }, [key == "action" ? [_c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["dcVideoTag:btn:edit"],
              expression: "['dcVideoTag:btn:edit']"
            }],
            on: {
              click: function click($event) {
                return _vm.handleAction("edit", record);
              }
            }
          }, [_vm._v("编辑")]), _c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["dcVideoTag:btn:delete"],
              expression: "['dcVideoTag:btn:delete']"
            }],
            on: {
              click: function click($event) {
                return _vm.handleAction("delete", record);
              }
            }
          }, [_vm._v("删除")])] : [_vm._v(_vm._s(record[key] || "---"))]], 2);
        }
      };
    })], null, true)
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  }), _c("DelUpdate", {
    attrs: {
      tipsDesc: _vm.tipsDesc,
      isDelete: true,
      comVisible: _vm.isConfirmVisible,
      loading: _vm.subLoading
    },
    on: {
      cancle: _vm.handleDelCancle,
      submit: _vm.handleDelSubmit
    }
  }), _c("DPopup", {
    attrs: {
      title: _vm.modalTitle,
      comVisible: _vm.modalVisible,
      isClosable: true
    },
    on: {
      cancel: function cancel($event) {
        _vm.modalVisible = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("Form", {
          attrs: {
            editId: _vm.editId,
            dataSource: _vm.activeItem
          },
          on: {
            cancel: _vm.handleFormCancel,
            submit: _vm.handleFormSubmit
          }
        })];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };