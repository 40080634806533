import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import { DcVideoTagApi } from "@/api";
export default {
  name: "DcVideoTagForm",
  components: {},
  props: {
    editId: [String, Number],
    action: {
      type: String,
      default: function _default() {
        return "add";
      }
    },
    dataSource: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      isEdit: "",
      spinning: false,
      loading: false,
      item: {}
    };
  },
  computed: {
    isDisabled: function isDisabled() {
      return this.action == "view";
    }
  },
  created: function created() {
    if (this.editId) {
      this.item = _objectSpread({}, this.dataSource);
      // this.findone();
    }
  },

  methods: {
    findone: function findone() {
      var _this = this;
      this.spinning = true;
      DcVideoTagApi.findone({
        id: this.editId
      }).then(function (res) {
        _this.spinning = false;
        _this.item = res;
      }).catch(function () {});
    },
    cancel: function cancel() {
      this.$emit("cancel");
    },
    submit: function submit() {
      var _this2 = this;
      if (this.loading) return;
      this.$refs.bannerForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          var fillable = ["name"];
          var params = {};
          fillable.forEach(function (field) {
            params[field] = _this2.item[field] || "";
          });
          var method = "add";
          if (_this2.editId) {
            method = "update";
            params.id = _this2.editId;
          }
          DcVideoTagApi[method](_objectSpread({}, params)).then(function () {
            _this2.toast("操作成功", "success");
            _this2.$emit("submit");
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    revalidateField: function revalidateField(field) {
      var _this3 = this;
      setTimeout(function () {
        _this3.$refs.bannerForm.validateField(field);
      }, 100);
    }
  }
};