import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import { mapState } from "vuex";
import Mixin from "@/utils/mixin";
import { columns } from "./config/index";
import { DcVideoTagApi } from "@/api";
import Form from "./components/form.vue";
export default {
  name: "dcVideoTagPage",
  components: {
    Form: Form
  },
  data: function data() {
    return {
      createForm: this.$form.createForm(this, {
        name: "dcVideoTagPage"
      }),
      loading: false,
      columns: columns,
      dataSource: [],
      tipsDesc: "",
      isConfirmVisible: false,
      subLoading: false,
      // modalVisible: false,

      action: "add",
      // 操作:add,edit,delete
      editId: undefined,
      modalVisible: false,
      page: 1,
      size: 20,
      total: 0,
      sorter: 1,
      defaultIndex: 0,
      types: [{
        id: 1,
        name: "已上架"
      }, {
        id: 0,
        name: "未上架"
      }]
    };
  },
  mixins: [Mixin],
  computed: _objectSpread(_objectSpread({}, mapState("dcVideo", ["sourceList"])), {}, {
    getDataWithKey: function getDataWithKey() {
      var _this = this;
      return function (key) {
        switch (key) {
          case "sourcetype":
            return _this.sourceList;
        }
      };
    },
    modalTitle: function modalTitle() {
      return this.action == "add" ? "新增" : this.action == "edit" ? "编辑" : "";
    },
    activeItem: function activeItem() {
      var _this2 = this;
      var ret = {};
      this.dataSource.some(function (item) {
        if (item.id == _this2.editId) {
          ret = item;
          return true;
        }
      });
      return ret;
    }
  }),
  created: function created() {
    this.getList();
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    // 取消删除
    handleDelCancle: function handleDelCancle() {
      this.isConfirmVisible = false;
      this.subLoading = false;
    },
    // 确定删除
    handleDelSubmit: function handleDelSubmit() {
      var _this3 = this;
      this.subLoading = true;
      DcVideoTagApi.update({
        id: this.editId,
        isdelete: 1
      }).then(function () {
        _this3.toast("操作成功", "success");
        _this3.isConfirmVisible = false;
        _this3.subLoading = false;
        _this3.getList();
      }).catch(function () {
        _this3.isConfirmVisible = false;
        _this3.subLoading = false;
      });
    },
    getList: function getList() {
      var _this4 = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          if (values.date && values.date.length > 0) {
            values.begintime = values.date[0].format("YYYY-MM-DD HH:mm:ss");
            values.endtime = values.date[1].format("YYYY-MM-DD HH:mm:ss");
            delete values.date;
          }
          _this4.loading = true;
          var params = _objectSpread({
            page: _this4.page,
            size: _this4.size
          }, values);
          DcVideoTagApi.list(_objectSpread({}, params)).then(function (res) {
            _this4.loading = false;
            _this4.dataSource = (res === null || res === void 0 ? void 0 : res.records) || [];
            _this4.total = (res === null || res === void 0 ? void 0 : res.total) || _this4.total;
          }).catch(function () {
            _this4.dataSource = [];
          }).finally(function () {
            setTimeout(function () {
              _this4.loading = false;
            }, 3000);
          });
        } else {
          console.log(err);
        }
      });
    },
    tableChange: function tableChange(pagination, filters, sorter) {
      if (!sorter.order) {
        this.sorter = 1;
        return;
      }
      this.sorter = sorter.order == "ascend" ? 3 : 2;
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.page = 1;
      this.size = 20;
      this.createForm.resetFields();
      this.handleSearch();
    },
    handleAction: function handleAction(action, record) {
      this.action = action;
      this.editId = (record === null || record === void 0 ? void 0 : record.id) || "";
      if (action == "add") {
        this.modalVisible = true;
      } else if (action == "edit") {
        this.modalVisible = true;
      } else if (action == "delete") {
        var name = (record === null || record === void 0 ? void 0 : record.name) || "ID:".concat(this.editId);
        this.tipsDesc = "\u786E\u8BA4\u5220\u9664\u6807\u7B7E\u3010".concat(name, "\u3011\u5417\uFF1F");
        this.isConfirmVisible = true;
      } else {
        return;
      }
    },
    handleShowStatus: function handleShowStatus(record) {
      var _this5 = this;
      this.loading = true;
      DcVideoTagApi.updatestatus({
        id: this.editId,
        isshow: record.isshow === 1 ? 0 : 1
      }).then(function () {
        _this5.toast("操作成功", "success");
        _this5.loading = false;
        _this5.getList();
      }).catch(function () {
        _this5.loading = false;
      });
    },
    /*******表单相关start********/handleFormCancel: function handleFormCancel() {
      // console.log("取消");
      this.modalVisible = false;
    },
    handleFormSubmit: function handleFormSubmit() {
      this.modalVisible = false;
      this.getList();
    } /*******表单相关end********/
  }
};