var columns = [{
  title: '标签ID',
  dataIndex: 'id',
  key: 'id',
  width: '35%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '标签名称',
  dataIndex: 'name',
  key: 'name',
  width: '45%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  // fixed: "right",
  width: '20%',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };