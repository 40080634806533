import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form-model", {
    ref: "bannerForm",
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      model: _vm.item
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "标签名称",
      prop: "name",
      rules: {
        required: true,
        message: "请输入标签名称.",
        whitespace: true
      }
    }
  }, [_c("a-input", {
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      disabled: _vm.isDisabled,
      "max-length": 300
    },
    model: {
      value: _vm.item.name,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "item.name"
    }
  })], 1)], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };